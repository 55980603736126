import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { YM, CodeName } from "@/store/common";
import { ItemListColModel, itemListColModelDefault, itemListColModelReportCut, RankData, pbKinds, cutRankList, teibans, itemListColModelKeppin, colKeysShiire } from "@/components/itemlist/ItemListTableModel";
import * as arrayUtil from "@/util/arrayUtil";
import { RequestParam } from "@/assets/apitype/itemList";
import { PageProps } from "@/assets/pageprops/itemlist";

//強制表示列
export const mustVisibleColumnsKey = [
  "no",
];
//非表示列　月次速報
export const unVisibleColumnsKeyQuick = [
];
//非表示列　月次確報
export const unVisibleColumnsKeyCommit = [
  "JJQT", "JJQP", "JJQR", "JJQD",
  "JJT", "JJP", "JJR", "JJD",
  "JKQT", "JKQP", "JKQR", "JKQD",
  "JKT", "JKP", "JKR", "JKD",
  "JRT", "JRP", "JRD", 

  //欠品責任別
  "KI1KQ", "KI1KA", "KI1KR",
  "KI2KQ", "KI2KA", "KI2KR",
  "KI3KQ", "KI3KA", "KI3KR",

  //欠品区分別
  "K10KQ", "K10KA", "K10KR",
  "K11KQ", "K11KA", "K11KR",
  "K12KQ", "K12KA", "K12KR",
  "K13KQ", "K13KA", "K13KR",
  "K14KQ", "K14KA", "K14KR",
  "K15KQ", "K15KA", "K15KR",
  "K16KQ", "K16KA", "K16KR",
  "K17KQ", "K17KA", "K17KR",
  "K19KQ", "K19KA", "K19KR",
  "K20KQ", "K20KA", "K20KR",
  "K21KQ", "K21KA", "K21KR",
  "K22KQ", "K22KA", "K22KR",
  "K29KQ", "K29KA", "K29KR",
  "K30KQ", "K30KA", "K30KR",
  "K31KQ", "K31KA", "K31KR",
  "K32KQ", "K32KA", "K32KR",
  "K33KQ", "K33KA", "K33KR",
  "K41KQ", "K41KA", "K41KR",
  "K34KQ", "K34KA", "K34KR",
  "K39KQ", "K39KA", "K39KR",
  "K40KQ", "K40KA", "K40KR",
  "K42KQ", "K42KA", "K42KR",
  "K43KQ", "K43KA", "K43KR",
  "K44KQ", "K44KA", "K44KR",
  "K49KQ", "K49KA", "K49KR",
  "K50KQ", "K50KA", "K50KR",
];
//受注関連項目
export const unVisibleColumnsKeyAdjus = [

  "JJQT", "JJQP", "JJQR", "JJQD",
  "JJT", "JJP", "JJR", "JJD",

  "JKQT", "JKQP", "JKQR", "JKQD",
  "JKT", "JKP", "JKR", "JKD",
  "JRT", "JRP", "JRD", 
  //欠品責任別
  "KI1KQ", "KI1KA", "KI1KR",
  "KI2KQ", "KI2KA", "KI2KR",
  "KI3KQ", "KI3KA", "KI3KR",

  //欠品区分別
  "K10KQ", "K10KA", "K10KR",
  "K11KQ", "K11KA", "K11KR",
  "K12KQ", "K12KA", "K12KR",
  // "K13KQ", "K13KA", "K13KR",
  "K14KQ", "K14KA", "K14KR",
  "K15KQ", "K15KA", "K15KR",
  "K16KQ", "K16KA", "K16KR",
  "K17KQ", "K17KA", "K17KR",
  "K19KQ", "K19KA", "K19KR",

  "K20KQ", "K20KA", "K20KR",
  "K21KQ", "K21KA", "K21KR",
  "K22KQ", "K22KA", "K22KR",
  "K29KQ", "K29KA", "K29KR",

  "K30KQ", "K30KA", "K30KR",
  "K31KQ", "K31KA", "K31KR",
  // "K32KQ", "K32KA", "K32KR",
  // "K33KQ", "K33KA", "K33KR",
  "K34KQ", "K34KA", "K34KR",
  // "K39KQ", "K39KA", "K39KR",
  "K40KQ", "K40KA", "K40KR",
  "K41KQ", "K41KA", "K41KR",
  "K42KQ", "K42KA", "K42KR",
  "K43KQ", "K43KA", "K43KR",
  // "K44KQ", "K44KA", "K44KR",
  // "K49KQ", "K49KA", "K49KR",
  "K50KQ", "K50KA", "K50KR",

];
//仕入実績
export const unVisibleColumnsKeyShiire = [
  ...colKeysShiire
];

//デフォルト表示列
export const defaultVisibleColumnsKey = [
  //以下に貼り付け
  "ISN", "IMN", "INM", "ICN", "IBN", "IJN", "ICD", "ICP", "IC1", "IC2", "ILI", "IPR", "CNM", 
  "JJT", "JJR", "JJD",
  "JKT", 
  "JRT", "JRD", 
  "KI1KR", "KI2KR", "KI3KR",
  "SQT", "SQP", "SQR", "SQD", "STT", "STR", "STD", "PTT", "PTD", "PRT", "CIT", "CIR", "CID", "CST", "CSR", "CSD", "CTT", "CTR", "CTD", "BTT", "BTD", "ATT", "ATR", "ATD", "ART", "ARD",
  "SHT", "SHR", "SHD",
];

export const reportCutVisibleColumnsKey = [
  //以下に貼り付け
  "ISN", "IMN", "INM", "ICN", "IBN", "IJN", "ICD", "ICP", "IC1", "IC2", "ILI", "IPR",
  "SLT", "SLR",
  "STT", "STR",
  "KUR", "KTR", "KRK",
  "ZTN", "RQTY", "YQTY", "ZQTY",
  "SQTC", "SQTN",
  "TGTL", "TG_ALL", "SGTL", "SG_ALL"
];

export const keppinVisibleColumnsKey = [
  //以下に貼り付け
  "IMN", "INM", "ICN", "IBN", "ICD", "ICP", "IC1", "IC2", "ILI", "IPR", "CNM", 
  "JJT", 
  "SQT", "STT", 
  "JKT", 
  "JRT", 
  "KI1KR", "KI2KR", "KI3KR",
];

//Page State
export type ItemListSaveState = {
  //年月
  ym: YM | null,
  //データ種別
  dataType: "quick" | "commit" | "unknown" | null,
  //検索対象
  containSEJ: boolean,

  group2: CodeName[] | null,
  group1: CodeName[] | null,
  shiten: CodeName[] | null,
  center: CodeName[] | null,
  shiire: CodeName[] | null,
  maker: CodeName[] | null,
  tokuisaki: CodeName[] | null,
  pb: CodeName[] | null,
  tag: CodeName[] | null,
  item: string | null,
  category: CodeName[] | null,
  ranks: RankData[] | null,
  pbKind: CodeName | null,
  rankC3Bl: number | null,
  teiban: CodeName | null,

  //検索終了した条件
  requestedParam : RequestParam,

  sort: {key:string, asc:boolean},
  // hiddenColumns: number[],
  hiddenColumnsKey: string[],
  visibleColumnsKey: string[],
  hiddenColumnsKey_setting: string[],  //表示設定
  groupingMaker: boolean, //互換性のため保持
  groupingCenter: boolean, //互換性のため保持
  grouping1: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping2: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping3: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping4: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  grouping5: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string,
  groupingOnly: boolean,
  keppinOnly: boolean,

  showRankDetail: boolean,
};

export const initialState: ItemListSaveState = {
  //年月
  ym: null,
  //データ種別
  dataType: "quick",
  //検索対象
  containSEJ: false,

  group2: [],
  group1:  [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  tokuisaki:  [],
  pb: [],
  tag: [],
  item: null,
  category: [],
  ranks: [],
  pbKind: null,
  rankC3Bl: null,
  teiban: null,

  requestedParam : {},

  sort: {
    key: "STT", //総売上本年実績
    asc: false, //降順
  },
  // hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: defaultVisibleColumnsKey,
  hiddenColumnsKey_setting: [],
  groupingMaker: true,
  groupingCenter: false,
  grouping1: 'maker',
  grouping2: '',
  grouping3: '',
  grouping4: '',
  grouping5: '',
  groupingOnly: false,
  keppinOnly: false,

  showRankDetail: false,
};

export const initialStateReportCut: ItemListSaveState = {
  //年月
  ym: null,
  //データ種別
  dataType: "commit",
  //検索対象
  containSEJ: false,

  group2: [],
  group1:  [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  tokuisaki:  [],
  pb: [],
  tag: [],
  item: null,
  category: [],
  ranks: [
    { rank_id: "1", rank_name: "ZX" },
    { rank_id: "2", rank_name: "Cɤ" },
  ],
  pbKind: pbKinds[2],
  rankC3Bl: 10,
  teiban: teibans[1],

  requestedParam : {},

  sort: {
    key: "SLT", //配送売上 本年実績
    asc: false, //降順
  },
  // hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: reportCutVisibleColumnsKey,
  hiddenColumnsKey_setting: [],
  groupingMaker: false,
  groupingCenter: false,
  grouping1: '',
  grouping2: '',
  grouping3: '',
  grouping4: '',
  grouping5: '',
  groupingOnly: false,
  keppinOnly: false,

  showRankDetail: true,
};
export const initialStateKeppin: ItemListSaveState = {
  //年月
  ym: null,
  //データ種別
  dataType: "commit",
  //検索対象
  containSEJ: false,

  group2: [],
  group1:  [],
  shiten: [],
  center: [],
  shiire: [],
  maker: [],
  tokuisaki:  [],
  pb: [],
  tag: [],
  item: null,
  category: [],
  ranks: [
    { rank_id: "1", rank_name: "ZX" },
    { rank_id: "2", rank_name: "Cɤ" },
  ],
  pbKind: pbKinds[2],
  rankC3Bl: 10,
  teiban: teibans[1],

  requestedParam : {},

  sort: {
    key: "SLT", //配送売上 本年実績
    asc: false, //降順
  },
  // hiddenColumns: [],
  hiddenColumnsKey: [],
  visibleColumnsKey: keppinVisibleColumnsKey,
  hiddenColumnsKey_setting: [],
  groupingMaker: false,
  groupingCenter: false,
  grouping1: '',
  grouping2: '',
  grouping3: '',
  grouping4: '',
  grouping5: '',
  groupingOnly: false,
  keppinOnly: true,

  showRankDetail: true,
};
//Page Slice
export type ItemListSaveReducer = {
  initOnDidMount: (state:ItemListSaveState, action: PayloadAction<ItemListSaveState>) => void,
  resetOnWillUnmount: (state:ItemListSaveState) => void,
  setPageQuery: (state:ItemListSaveState, action: PayloadAction<PageProps>) => void,
  setYM: (state:ItemListSaveState, action: PayloadAction<YM>) => void,
  setYMList: (state:ItemListSaveState, action: PayloadAction<YM[]>) => void,
  setDataType: (state:ItemListSaveState, action: PayloadAction<string>) => void,
  setContainSEJ: (state:ItemListSaveState, action: PayloadAction<boolean>) => void,
  setGroup2: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setGroup2List: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setGroup1: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setGroup1List: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiten: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setShitenList:(state:ItemListSaveState, action: PayloadAction<CodeName[]>)  => void,
  setCenter: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setCenterList:(state:ItemListSaveState, action: PayloadAction<CodeName[]>)  => void,
  setShiire: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setShiireList: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setMaker: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setMakerList: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setTokuisaki: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setTokuisakiList: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setPb: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setPbList: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setTag: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setTagList: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setItem: (state:ItemListSaveState, action: PayloadAction<string>) => void,
  setCategory: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setCategoryList: (state:ItemListSaveState, action: PayloadAction<CodeName[]>) => void,
  setPbKind: (state:ItemListSaveState, action: PayloadAction<CodeName>) => void,
  setRanks: (state:ItemListSaveState, action: PayloadAction<RankData[]>) => void,
  setRankC3Bl: (state:ItemListSaveState, action: PayloadAction<number>) => void,
  setTeiban: (state:ItemListSaveState, action: PayloadAction<CodeName>) => void,
  clearOption: (state:ItemListSaveState, action: PayloadAction<{ ymList: YM[] }>) => void,
  setRequestedParam: (state:ItemListSaveState, action: PayloadAction<RequestParam>) => void,
  setSort: (state:ItemListSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) => void,
  setGroupings: (state:ItemListSaveState, action: PayloadAction<('item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string)[]>) => void,
  setGrouping1: (state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>) => void,
  setGrouping2: (state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>) => void,
  setGrouping3: (state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>) => void,
  setGrouping4: (state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>) => void,
  setGrouping5: (state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>) => void,
  setGroupingOnly: (state:ItemListSaveState, action: PayloadAction<boolean>) => void,
  setKeppinOnly: (state:ItemListSaveState, action: PayloadAction<boolean>) => void,
  // setGroupingMaker: (state:ItemListSaveState, action: PayloadAction<boolean>) => void,
  // setGroupingCenter: (state:ItemListSaveState, action: PayloadAction<boolean>) => void,
  // hideColumnsChange: (state:ItemListSaveState, action: PayloadAction<{columns:number[], keys:string[]}>) => void,
  hideColumnKeysChange: (state:ItemListSaveState, action: PayloadAction<string[]>) => void,
  hideColumnKeysChangeSetting: (state:ItemListSaveState, action: PayloadAction<string[]>) => void,
  showRankDetailChange: (state:ItemListSaveState, action: PayloadAction<boolean>) => void,
}
const createReducerContent = (name:string, colModel:ItemListColModel, initialState: ItemListSaveState, useSave: boolean):ItemListSaveReducer => {return {
    //componentDidMount
    initOnDidMount(state:ItemListSaveState, action: PayloadAction<ItemListSaveState>) {
      //追加属性等はlocalstorageから復元したときにundefinedになっている場合があるためチェックする
      if (!state.ym) {
        state.ym = initialState.ym;
      }
      if (!state.dataType) {
        state.dataType = initialState.dataType;
      }
      if (!state.group2 || state.group2.length == 0) {
        state.group2 = initialState.group2;
      }
      if (!state.group1 || state.group1.length == 0) {
        state.group1 = initialState.group1;
      }
      if (!state.shiten || state.shiten.length == 0) {
        state.shiten = initialState.shiten;
      }
      if (!state.center || state.center.length == 0) {
        state.center = initialState.center;
      }
      if (!state.shiire || state.shiire.length == 0) {
        state.shiire = initialState.shiire;
      }
      if (!state.maker || state.maker.length == 0) {
        state.maker = initialState.maker;
      }
      if (!state.tokuisaki || state.tokuisaki.length == 0) {
        state.tokuisaki = initialState.tokuisaki;
      }
      if (!state.pb || state.pb.length == 0) {
        state.pb = initialState.pb;
      }
      if (!state.tag || state.tag.length == 0) {
        state.tag = initialState.tag;
      }
      if (!state.item || state.item.length == 0) {
        state.item = initialState.item;
      }
      if (!state.category || state.category.length == 0) {
        state.category = initialState.category;
      }
      if (!state.ranks || state.ranks.length == 0) {
        state.ranks = initialState.ranks;
      } else {
        // ZをZXにする
        state.ranks = state.ranks.map(rank => cutRankList.find(d => d.rank_id == rank?.rank_id)).filter(d => d?.rank_id);
      }
      if (!state.pbKind) {
        state.pbKind = initialState.pbKind;
      }
      if (!state.rankC3Bl) {
        state.rankC3Bl = initialState.rankC3Bl;
      }
      if (!state.teiban) {
        state.teiban = initialState.teiban;
      }

      if(!state.sort || !state.sort.key) {
        state.sort = initialState.sort;
      }

      //初期表示列0配列は、常に全項目表示とする
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = colModel.colKeys;
      }
      else if(!state.visibleColumnsKey) {
        state.visibleColumnsKey = initialState.visibleColumnsKey;
      }
      //強制選択列
      state.visibleColumnsKey = arrayUtil.union(arrayUtil.and(colModel.colKeys, mustVisibleColumnsKey), state.visibleColumnsKey);
      //非表示列
      state.hiddenColumnsKey = arrayUtil.not([...colModel.colKeys, "TG_ALL", "SG_ALL"], state.visibleColumnsKey);

      if (!state.hiddenColumnsKey_setting || state.hiddenColumnsKey_setting.length == 0) {
        state.hiddenColumnsKey_setting = state.hiddenColumnsKey;
      }
  
      if(state.grouping1 === undefined) {
        if(state.groupingMaker === undefined) {
          state.grouping1 = initialState.grouping1;
        }
        else {
          state.grouping1 = state.groupingMaker ? 'maker' : '';
        }
      }
      if(state.grouping2 === undefined) {
        if(state.groupingCenter === undefined) {
          state.grouping2 = initialState.grouping2;
        }
        else {
          state.grouping2 = state.groupingCenter ? 'center' : '';
        }
      }
      if(state.grouping3 === undefined) {
        state.grouping3 = initialState.grouping3;
      }
      if(state.grouping4 === undefined) {
        state.grouping4 = initialState.grouping4;
      }
      if(state.grouping5 === undefined) {
        state.grouping5 = initialState.grouping5;
      }

      //ランク凡例表示
      if(state.showRankDetail === undefined) {
        state.showRankDetail = initialState.showRankDetail;
      }

      if(state.requestedParam === undefined) {
        state.requestedParam = initialState.requestedParam;
      }


    },
    //componentWillUnmount
    resetOnWillUnmount(state:ItemListSaveState) {
      //初期表示列0配列は、常に全項目表示とするため、記憶しない
      if(initialState.visibleColumnsKey.length == 0) {
        state.visibleColumnsKey = [];
      }
    },
    //単品別一覧から渡されたQuery
    setPageQuery(state:ItemListSaveState, action: PayloadAction<PageProps>) {
      const newState = {
      };
      if(action.payload.grouping1 || action.payload.grouping1 === '') {
        newState["grouping1"] = action.payload.grouping1;
      }
      if(action.payload.grouping2 || action.payload.grouping2 === '') {
        newState["grouping2"] = action.payload.grouping2;
      }
      if(action.payload.grouping3 || action.payload.grouping3 === '') {
        newState["grouping3"] = action.payload.grouping3;
      }
      if(action.payload.grouping4 || action.payload.grouping4 === '') {
        newState["grouping4"] = action.payload.grouping4;
      }
      if(action.payload.grouping5 || action.payload.grouping5 === '') {
        newState["grouping5"] = action.payload.grouping5;
      }
      if(action.payload.groupingOnly || action.payload.groupingOnly === false) {
        newState["groupingOnly"] = !(!action.payload.groupingOnly);
      }
      if(action.payload.keppinOnly || action.payload.keppinOnly === false) {
        newState["keppinOnly"] = !(!action.payload.keppinOnly);
      }

      Object.assign(state, newState);
    },

    // Option YM
    setYM(state:ItemListSaveState, action: PayloadAction<YM>) {
      state.ym = action.payload;
    },
    setYMList(state:ItemListSaveState, action: PayloadAction<YM[]>) {
      const ymList = action.payload;
      let ym = state.ym && state.ym.code ? ymList.find((ym) => ym.code == state.ym.code) : null;
      if (!ym) {
        ym = ymList[0];
      }
      state.ym = ym;
    },
    //データ種別
    setDataType(state:ItemListSaveState, action: PayloadAction<string>) {
      switch (action.payload) {
        case "quick":
          state.dataType = "quick";
          break;
        case "commit":
          state.dataType = "commit";
          break;
        case "unknown":
          state.dataType = "unknown";
          break;
        default:
          state.dataType = "quick";
          break;
      }
    },
    //検索対象
    setContainSEJ(state:ItemListSaveState, action: PayloadAction<boolean>) {
      state.containSEJ = action.payload;
    },
    setGroup2 (state:ItemListSaveState, action: PayloadAction<CodeName[]>)     {
      state.group2 = action.payload;
    },
    setGroup2List(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.group2 = getData(state.group2, action.payload);
    },
    setGroup1(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.group1 = action.payload;
    },
    setGroup1List(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.group1 = getData(state.group1, action.payload);
    },
    setShiten(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.shiten = action.payload;
    },
    setShitenList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.shiten = getData(state.shiten, action.payload);
    },
    setCenter(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.center = action.payload;
    },
    setCenterList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.center = getData(state.center, action.payload);
    },
    setShiire(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.shiire = action.payload;
    },
    setShiireList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.shiire = getData(state.shiire, action.payload);
    },
    setMaker(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.maker = action.payload;
    },
    setMakerList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.maker = getData(state.maker, action.payload);
    },
    setTokuisaki (state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.tokuisaki = action.payload;
    },
    setTokuisakiList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.tokuisaki = getData(state.tokuisaki, action.payload);
    },
    setPb(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.pb = action.payload;
    },
    setPbList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.pb = getData(state.pb, action.payload);
    },
    setTag(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.tag = action.payload;
    },
    setTagList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.tag = getData(state.tag, action.payload);
    },
    setItem(state:ItemListSaveState, action: PayloadAction<string>) {
      state.item = action.payload;
    },
    setCategory(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.category = action.payload;
    },
    setCategoryList(state:ItemListSaveState, action: PayloadAction<CodeName[]>) {
      state.category = getData(state.category, action.payload);
    },
    setRanks(state:ItemListSaveState, action: PayloadAction<RankData[]>) {
      state.ranks =[ ...action.payload];
    },
    setPbKind (state:ItemListSaveState, action: PayloadAction<CodeName>) {
      state.pbKind = action.payload;
    },
    setRankC3Bl(state:ItemListSaveState, action: PayloadAction<number>) {
      state.rankC3Bl = action.payload;
    },
    setTeiban(state: ItemListSaveState, action: PayloadAction<CodeName>) {
      state.teiban = action.payload;
    },
    clearOption(state:ItemListSaveState, action: PayloadAction<{
      ymList: YM[],
    }>) {
      Object.assign(state, {
        ym: action.payload.ymList[0],
        dataType: initialState.dataType,
        containSEJ: initialState.containSEJ,
        group2: initialState.group2,
        group1: initialState.group1,
        shiten: initialState.shiten,
        center: initialState.center,
        shiire: initialState.shiire,
        maker: initialState.maker,
        tokuisaki: initialState.tokuisaki,
        pb: initialState.pb,
        tag: initialState.tag,
        item: initialState.item,
        category: initialState.category,
        teiban: initialState.teiban,
      });
    },

    //検索条件
    setRequestedParam(state:ItemListSaveState, action: PayloadAction<RequestParam>) {
      state.requestedParam = useSave? action.payload : initialState.requestedParam;
    },

    setSort(state:ItemListSaveState, action: PayloadAction<{sortKey:string, sortAsc: boolean}>) {
      console.log('setSort');
      const key = action.payload.sortKey;
      const asc = action.payload.sortAsc;
      state.sort = {
        key: key,
        asc: asc,
      }
    },
    setGroupings(state:ItemListSaveState, action: PayloadAction<('item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string)[]>){
      console.log('setGroupings');
      state.grouping1 = '';
      state.grouping2 = '';
      state.grouping3 = '';
      state.grouping4 = '';
      state.grouping5 = '';

      action.payload.forEach((grouping, index) => {
        switch (index) {
          case 0:
            state.grouping1 = grouping;
            break;
          case 1:
            state.grouping2 = grouping;
            break;
          case 2:
            state.grouping3 = grouping;
            break;
          case 3:
            state.grouping4 = grouping;
            break;
          case 4:
            state.grouping5 = grouping;
            break;
          default:
            break;
        }
      });
    },
    setGrouping1(state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>){
      console.log('setGrouping1');
      state.grouping1 = action.payload;
    },
    setGrouping2(state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>){
      console.log('setGrouping2');
      state.grouping2 = action.payload;
    },
    setGrouping3(state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>){
      console.log('setGrouping3');
      state.grouping3 = action.payload;
    },
    setGrouping4(state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>){
      console.log('setGrouping4');
      state.grouping4 = action.payload;
    },
    setGrouping5(state:ItemListSaveState, action: PayloadAction<'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string>){
      console.log('setGrouping5');
      state.grouping5 = action.payload;
    },
    setGroupingOnly(state:ItemListSaveState, action: PayloadAction<boolean>){
      console.log('setGroupingOnly');
      state.groupingOnly = action.payload;
    },
    setKeppinOnly(state:ItemListSaveState, action: PayloadAction<boolean>){
      console.log('setKeppinOnly');
      state.keppinOnly = action.payload;
    },
    // setGroupingMaker(state:ItemListSaveState, action: PayloadAction<boolean>) {
    //   console.log('setGroupingMaker');
    //   const groupingMaker = action.payload;
    //   state.groupingMaker = groupingMaker;
    // },
    // setGroupingCenter(state:ItemListSaveState, action: PayloadAction<boolean>) {
    //   console.log('setGroupingCenter');
    //   const groupingCenter = action.payload;
    //   state.groupingCenter = groupingCenter;
    // },
    // hideColumnsChange(state:ItemListSaveState, action: PayloadAction<{columns:number[], keys:string[]}>){
    //   Object.assign(state, {
    //     hiddenColumns: action.payload.columns,
    //     hiddenColumnsKey: action.payload.keys,
    //     visibleColumnsKey: arrayUtil.not(colModel.colKeys, action.payload.keys),
    //   });
    // },
    hideColumnKeysChange(state:ItemListSaveState, action: PayloadAction<string[]>){
      Object.assign(state, {
        // hiddenColumns: colModel.colFromKeys(action.payload),
        hiddenColumnsKey: action.payload,
        visibleColumnsKey: arrayUtil.not(colModel.colKeys, action.payload),
      });
    },
    hideColumnKeysChangeSetting(state:ItemListSaveState, action: PayloadAction<string[]>){
      console.log('store.hideColumnKeysChangeSetting');
      const hiddenColumnsKey = action.payload;
  
      Object.assign(state, {
        hiddenColumnsKey_setting: hiddenColumnsKey,
      });
    },
    showRankDetailChange(state:ItemListSaveState, action: PayloadAction<boolean>){
      state.showRankDetail = action.payload;
    },
  }};

const getData = (target: CodeName[], list: CodeName[]): CodeName[] => {
  let datas = [];
  if (target && target.length > 0) {
    const codes: string[] = target.filter(data => !(!data)).map(data => data.code);
    datas = list.filter(data => codes.indexOf(data.code) !== -1)
      .filter((value, index, self) => self.findIndex(s => s.code == value.code) === index);
  }
  return datas;
};

const createSliceContent = (name: string, colModel: ItemListColModel, initialState: ItemListSaveState, useSave: boolean) => createSlice({
  name: name,
  initialState: initialState,
  reducers: createReducerContent(name, colModel, initialState, useSave),
});

export const hasGroupingOnState = (state:ItemListSaveState, grouping: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string):boolean => {
  return hasGrouping([
    state.grouping1,
    state.grouping2,
    state.grouping3,
    state.grouping4,
    state.grouping5,
  ], grouping);
}
export const hasGrouping = (groupings:string[], grouping: ''|'item'|'shiire'|'maker'|'shiten'|'center'|'group2'|'group1'|'tokuisaki'|'category'|'pb'|string):boolean => {
  return groupings.indexOf(grouping) !== -1;
}

//Page Slice Export
//itemListSave
export const itemListSaveSlice = createSliceContent("itemListSave", itemListColModelDefault, initialState, true);
export const itemListDirectSaveSlice = createSliceContent("itemListDirectSave", itemListColModelDefault, initialState, true);
export const salesItemListSaveSlice = createSliceContent("salesItemListSave", itemListColModelDefault, initialState, false);
export const itemListReportSSaveSlice = createSliceContent("itemListReportSSave", itemListColModelDefault, initialState, true);
export const itemListReportCutSaveSlice = createSliceContent("itemListReportCutSave", itemListColModelReportCut, initialStateReportCut, true);
export const keppinItemListSaveSlice = createSliceContent("keppinItemListSave", itemListColModelKeppin, initialStateKeppin, false);

