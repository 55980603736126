import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { calcData, parseData, RowData } from "@/store/itemlist/itemListTmp";
import * as outputDataList from "@/assets/apitype/itemList";
import { CodeName, YM } from "@/store/common";
import * as compareUtil from "@/util/compareUtil";

export interface RowDataCenter {
  no?: string,
  ISC?: string | null, ISN?: string | null, IMC?: string | null, IMN?: string | null, INM?: string | null, ICC?: string | null,
  ICN?: string | null, IBC?: string | null, IBN?: string | null, IJN?: string | null, ICD?: string | null, ICP?: string | null,
  IC1?: number | null, IC2?: number | null, ILI?: number | null, IPR?: number | null,
}

//Page State
export type OutputDataCenterTmpState = {
  //年月
  ymList: YM[],
  group1List: CodeName[],
  syuyakuList: CodeName[],
  //倉庫
  centerList: CodeName[],

  //検索する条件
  requestParams: outputDataList.RequestParam,
  //検索終了した条件
  retrievedID: string,

  progress: Record<string, unknown>,
  datas: RowDataCenter[],

  errorMessage: string | null,
};

export const initialState: OutputDataCenterTmpState = {
  //年月
  ymList: [],
  group1List: [],
  syuyakuList: [],
  //倉庫
  centerList: [],

  requestParams: null,
  retrievedID: null,

  progress: {},
  datas: [],

  errorMessage: null,
};

//Page Slice
const createSliceContent = (name: string) => createSlice({
  name: name,
  initialState,
  reducers: {
    setYMList(state: OutputDataCenterTmpState, action: PayloadAction<YM[]>) {
      state.ymList = action.payload;
    },
    setGroup1List(state: OutputDataCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.group1List = action.payload;
    },
    setSyuyakuList(state: OutputDataCenterTmpState, action: PayloadAction<CodeName[]>) {
      state.syuyakuList = action.payload;
    },
    //検索条件
    setRequestParams(state: OutputDataCenterTmpState, action: PayloadAction<outputDataList.RequestParam>) {
      state.requestParams = action.payload;
    },
    setRetrievedID(state: OutputDataCenterTmpState, action: PayloadAction<string>) {
      state.retrievedID = action.payload;
    },
    putProgress(state: OutputDataCenterTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = { ...state.progress };
      progressNew[key] = true;
      state.progress = progressNew;
    },
    removeProgress(state: OutputDataCenterTmpState, action: PayloadAction<string>) {
      const key = action.payload;
      const progressNew = {};
      Object.keys(state.progress).forEach(k => {
        if (key != k) {
          progressNew[k] = true;
        }
      })
      state.progress = progressNew;
    },
    searched(state: OutputDataCenterTmpState, action: PayloadAction<RowData[]>) {
      //計算
      let rowDatas = action.payload;

      rowDatas = parseData(rowDatas);

      const totalSLT = 0;           //KUR 売上構成比に使用のため不要
      const totalTeibanSLT = 0;     //KUR 売上構成比に使用のため不要
      const totalTeibangaiSLT = 0;  //KUR 売上構成比に使用のため不要
      const calcKeppin = false;
      rowDatas.forEach(rowData => {
        calcData(rowData, 'item', totalSLT, totalTeibanSLT, totalTeibangaiSLT, calcKeppin);
      });

      let centers = [];
      const itemMap = {};
      rowDatas.filter(rowData => rowData.LQT > 0).forEach(rowData => {
        const item: RowDataCenter = {
          ISC: rowData.ISC,
          ISN: rowData.ISN,
          IMC: rowData.IMC,
          IMN: rowData.IMN,
          INM: rowData.INM,
          ICC: rowData.ICC,
          ICN: rowData.ICN,
          IBC: rowData.IBC,
          IBN: rowData.IBN,
          IJN: rowData.IJN,
          ICD: rowData.ICD,
          ICP: rowData.ICP,
          IC1: rowData.IC1,
          IC2: rowData.IC2,
          ILI: rowData.ILI,
          IPR: rowData.IPR,
        };
        const itemKey = JSON.stringify(item);
        const centerCD = rowData.CCD;
        const centerNM = rowData.CNM;
        centers.push(new CodeName({ code: centerCD, name: centerNM }));

        let centerMap = itemMap[itemKey];
        if (!centerMap) {
          centerMap = {};
          itemMap[itemKey] = centerMap;
        }
        centerMap[centerCD] = rowData.LQT;
      });

      centers = centers
        .filter((value, index, self) => self.findIndex(cn => cn.code == value.code) === index)
        .sort((a, b) => compareUtil.compareAny(a.code, b.code, true));

      let datas: RowDataCenter[] = [];
      Object.keys(itemMap)
        .forEach(itemKey => {
          let data: RowDataCenter = JSON.parse(itemKey);
          centers.forEach((center, index) => {
            const nn = `00${index + 1}`.slice(-3);
            const key = `LQT${nn}`;
            data = {
              ...data,
              [key]: itemMap[itemKey][center.code]
            };
          });
          datas.push(data);
        });
      datas = resetRowNo(datas); //列番号の振りなおし

      state.datas = datas;
      state.centerList = centers;
    },
    setDatasCenter(state: OutputDataCenterTmpState, action: PayloadAction<RowDataCenter[]>) {
      const datas = action.payload;
      Object.assign(state, {
        datasCenter: datas,
      });
    },
    setErrorMessage(state: OutputDataCenterTmpState, action: PayloadAction<string>) {
      state.errorMessage = action.payload;
    }
  },
});

//no振りなおし
const resetRowNo = (datas: RowDataCenter[]): RowDataCenter[] => {
  let no = 1;
  datas = datas.map((row) => {
    return {
      ...row,
      no: "" + (no++),
    }
  });
  return datas;
}
export const getOptionLabel = (option: CodeName) => {
  return option && option.name ? (option.code + ' ' + option.name) : "";
}


//Page Slice Export
export const outputDataCenterTmpSlice = createSliceContent("outputDataCenterTmp");
